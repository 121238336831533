import { FC } from 'react';
import { ObjectProps } from './GlobalObject';
import CharacterLimitWarning from './MaxCharacterLimitText';
import PrintWidth from './PrintWidth';
import PrintWidthPopup from './PrintWidthPopup';

interface IProps extends ObjectProps {
    isSEO?: boolean;
}

const Textarea:FC<IProps> = (props) => {

    const maxLength = Number(props.maxlength) || 1024
    const minLength = Number(props.minlength) || 0

    return (
        <div>
            <div className='flex align-middle'><label htmlFor={props.name}>{props.title || props.name} {props.required && <span className="text-gray-400 text-xs">(required)</span>}</label> {props.isSEO && <div> <PrintWidthPopup fieldName={props.name} /> </div> }</div>
            {props.hint && <div className={"text-sm text-gray-500 pb-2"}>{props.hint}</div>}
            <textarea id={props.name} rows={3} name={props.name} required={props.required} placeholder={props.hint || ''} maxLength={maxLength} minLength={minLength} onChange={props.onChange}>{props.value}</textarea>
            {props.isSEO && <div> <PrintWidth value={props.value || ''} fieldName={props.name} /> </div> }
            <div className="flex justify-between pt-1">
                <CharacterLimitWarning
                    maxLength={maxLength}
                    minLength={minLength}
                    valueLength={props.value?.length || 0}
                />
            </div>        
        </div>
    )
}

export default Textarea;
