import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, FormEvent, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';

interface Props {
    enabledLanguages: {
      name: string;
      code: string;
    }[]
    handleDelete(languageCode: string): void;
    handleAddTranslation(e: FormEvent<HTMLFormElement>, name: string, languageCode: string): void
}

export const TranslationManager: FC<Props> = ({ enabledLanguages, handleDelete, handleAddTranslation }) => {
    const [name, setName] = useState('');
    const [languageCode, setLanguageCode] = useState('');
    const onSubmitClick = (e: FormEvent<HTMLFormElement>) => {
        handleAddTranslation(e, name, languageCode)
        setName('')
        setLanguageCode('')
    }
    return (
        <>
            <h2 className={"pageHeading border-b border-gray-200 pb-4 mb-2"}>Translation Manager</h2>
            <div className={"py-4"}>
                <div className={"smallHeading pb-2"}>Manage</div>
                <div className={"h-48 overflow-y-auto overflow-x-hidden"}>
                    {enabledLanguages.map(({code, name}) =>
                        <div className={"flex px-4 py-2 mb-2 bg-gray-200 rounded-lg text-gray-700"} key={code}>
                            <div className={"w-full font-semibold"}>
                                {name}
                            </div>
                            {code !== 'en' &&
                                <div className={"w-auto ml-auto"}>
                                    <button type={"button"} className="text-red-400 hover:text-red-500 focus:outline-none transition ease-in-out duration-300" onClick={() => confirmAlert({ message: "Are you sure you want to remove this translation? All related documents will be deleted", buttons: [{ label: "Yes", onClick: () => handleDelete(code) }, { label: "No", onClick: () => false }] })}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </div>
                            }
                        </div>
                    )}
                </div>
                <h2 className={"smallHeading pt-4"}>Add Translation</h2>
                <form method={"post"} onSubmit={onSubmitClick} className={"grid grid-cols-1 gap-2"}>
                    <div className={"pt-2"}>
                        <input type={"text"} className={`input-basic`} name={"name"} placeholder={"Language name - French"} onChange={(e) => setName(e.target.value)} value={name} required />
                    </div>
                    <input type={"text"} className={"input-basic"} name={"url"} placeholder={"Language Code - fr"} onChange={(e) => setLanguageCode(e.target.value)} value={languageCode} required />
                    <div className={"flex"}>
                        <button type={"submit"} className={"button-small button-cyan ml-auto"}>
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}
