import {FC, useState, createContext, useContext, useEffect, useRef} from 'react';
import axios, { CancelTokenSource } from "axios";
import Config from "../../Config";
import {toast} from "react-toastify";
import { AnnouncementData } from '../../interfaces/GlobalData';
import { ProjectData } from '../../interfaces/ProjectData';
import {useEditorContext} from './Editor.context';

interface ContextType {
    announcement: AnnouncementData | null;
    setAnnouncement: (data: AnnouncementData) => void;
    localAnnouncement: string;
    setLocalAnnouncement: (data: string) => void;
    projectData: ProjectData | null;
    setProjectData: (projectData: ProjectData) => void;
    localLanguage: [name: string, code: string];
    setLocalLanguage: (language: [name: string, code: string]) => void ;
}

export const BaseContext = createContext<ContextType | null>(null);

export const BaseProvider: FC = ({ children }) => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const [localLanguage, setLocalLanguage] = useState<[string, string]>(() => {
        const language = localStorage.getItem('localLanguageName')
        const languageCode = localStorage.getItem('localLanguageCode')
        if (!language || !languageCode) {
            return ['English', 'en']
        }
        return [language, languageCode]
    });
    useEffect(() => {
        localStorage.setItem('localLanguageName', localLanguage[0]);
        localStorage.setItem('localLanguageCode', localLanguage[1]);
    }, [localLanguage]);

    const [ projectData, setProjectData ] = useState<ProjectData | null>(null);
    const [ announcement, setAnnouncement ] = useState<AnnouncementData | null>(null);
    const [ localAnnouncement, setLocalAnnouncement ] = useState<string>(localStorage.getItem('localAnnouncement') || '');

    const { sessionData } = useEditorContext();
  console.log(sessionData)

    useEffect(() => {
        localStorage.setItem('localAnnouncement', localAnnouncement);
    }, [ localAnnouncement, setLocalAnnouncement ]);

    useEffect(() => {
        if (!projectData) {
            return
        }
        if (!projectData.translationEnabled && localLanguage[1] !== "en") {
            setLocalLanguage(["English", "en"])
        } else if (projectData.translationEnabled && !projectData.enabledLanguages.some(({code }) => code === localLanguage[1])) {
            setLocalLanguage(["English", "en"])
        } else {
        }

    }, [projectData, localLanguage]);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();

        axios.get(`${Config.apiUrl}/globals/announcement`,{ cancelToken: axiosCancelSource.current?.token, withCredentials: true }).then((response) => {
            if (response.data.message) 
                setAnnouncement(response.data);
        }).catch(() => toast.error('Unexpected error occurred!'));

        axios.get(`${Config.apiUrl}/editors/project`, { cancelToken: axiosCancelSource.current?.token, withCredentials: true }).then((response) => {
            if (response.data) {
                setProjectData(response.data);
            }
        }).catch(() => toast.error('Unexpected error occurred!'));

        return () => axiosCancelSource.current?.cancel();
    }, [sessionData]);

    return <BaseContext.Provider value={{ announcement, setAnnouncement, localAnnouncement, setLocalAnnouncement, projectData, setProjectData, localLanguage, setLocalLanguage }}>
        {children}
    </BaseContext.Provider>;
}

export const useBaseContext = (): ContextType => {
    const context = useContext(BaseContext);

    if (context == null) 
        throw new Error('useBaseContext must be used within a BaseProvider');

    return context;
}
