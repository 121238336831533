import { FC, useMemo } from "react";

type CharacterLimitWarningProps = {
    maxLength: number;
    minLength: number;
    valueLength: number;
};
const CharacterLimitWarning: FC<CharacterLimitWarningProps> = ({
    valueLength,
    maxLength,
    minLength,
}) => {
    let notifyClass: string;
    if (valueLength >= minLength && valueLength <= maxLength) {
        notifyClass = "text-gray-500";
    } else {
        notifyClass = "text-error-100";
    }

    const notifyText = useMemo(() => {
        return `${maxLength - valueLength} characters left ${minLength ? `// ${minLength} minimum` : ""}`;
    }, [maxLength, valueLength, minLength]);
    return <div className={`text-xs ${notifyClass}`}>{notifyText}</div>;
};

export default CharacterLimitWarning;
