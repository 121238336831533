import {FC, useState, useEffect, useCallback, useRef, FormEvent} from 'react';
import {Redirect, useParams, Prompt} from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';
import { toast } from 'react-toastify';
import { DocumentData } from '../../../interfaces/DocumentData';
import { CommitData } from '../../../interfaces/CommitData';
import { useEditorContext } from '../../contexts/Editor.context';
import GlobalObject, { ObjectProps } from "../../widgets/GlobalObject";
import {useSchemaContext} from "../../contexts/Schema.context";
import {faChevronDown, faCog, faCropAlt, faEye, faEyeSlash, faLink, faSearchLocation, faTimes, faUndo} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Config from "../../../Config";
import Commit from "../../other/Commit";
import useCSRF from "../../hooks/useCSRF";
import TitleService from "../../../services/TitleService";
import moment from "moment";
import {useTagContext} from "../../contexts/Tag.context";
import Loading from "../../other/Loading";
import {BackupData} from "../../../interfaces/BackupData";
import ReactTooltip from 'react-tooltip';
import Breadcrumb from '../../other/Breadcrumb';
import DateTimePicker  from 'react-datetime-picker';
import { confirmAlert } from '../../other/TextConfirm';
import {useBaseContext} from '../../contexts/Base.context';
import {EditorData} from '../../../interfaces/EditorData';

type RestfulUrlButtonParams = {
    doc?: DocumentData;
    sessionData: EditorData | null;
    collectionId?: string;
}
const RestfulUrlButton: FC<RestfulUrlButtonParams> = ({ doc, sessionData, collectionId }) => {
    if (sessionData && sessionData.level >= 3 && doc) {
        let href = `${Config.publicUrl}/${sessionData.projectUUID}/${collectionId}/${doc.documentId}`
        const params = []
        if (doc.language && doc.language !== 'en') {
            href += `/${doc.language}`
        }
        if (doc.draft) {
            params.push('preview=true')
        }
        if (params.length) {
            href = href + '?' + params.join('&')
        }
        return (<a data-tip="RESTful URL" className={"w-12 button-small button-black"} target={"_blank"} rel={"noopener noreferrer"} href={href}>
            <FontAwesomeIcon icon={faLink} />
        </a>)
    } else {
        return null
    }
}

type DeleteDocumentProps = {
    language?: string;
    onClick(): void;
    name: string
}
const DeleteDocumentButton: FC<DeleteDocumentProps> = ({ language, onClick, name }) => {
    let disabled = false
    let title = ''
    let className = 'button-small button-red'
    if (language && language !== 'en') {
        disabled = true
        title = 'Select default language to delete the document'
        className += ' button-disabled'
    }
    return (
        <div>
            <label className="font-semibold block mb-3">Danger Zone</label>
            <button
                disabled={disabled}
                title={title}
                type={"button"}
                className={className}
                onClick={onClick}>Delete {name}</button>
            {disabled && <div>Select default language to delete the document</div>}
        </div>
    )
}

const Documents: FC = () => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const { _csrf } = useCSRF();
    const { sessionData } = useEditorContext();
    const { schemaData } = useSchemaContext();
    const { tagData } = useTagContext();
    // const [ tab, setTab ] = useState<number>(0);
    const [ unsaved, setUnsaved ] = useState<boolean>(false);
    const [ modal, setModal ] = useState<boolean>(false);
    const [ redirect, setRedirect ] = useState<string>('');
    const [ dropdown, setDropdown ] = useState<boolean>(false);
    const [ commitData, setCommitData ] = useState<CommitData[]>([]);
    const [ backupData, setBackupData ] = useState<BackupData[]>([]);
    const { localLanguage } = useBaseContext()
    const [ useDocument, setUseDocument ] = useState<DocumentData | null>(null);
    const [ toggleBackups, setToggleBackups ] = useState<boolean>(false);
    const { document, id } = useParams<{ id?: string; document?: string }>();


    const getData = useCallback(() => {
      if (useDocument) {
        setUseDocument(null)
      }
      
        if (document) {
            axios.get(`${Config.apiUrl}/documents/list/${id}/${document}?lang=${localLanguage[1]}`,{ cancelToken: axiosCancelSource.current?.token, withCredentials: true })
                .then((response) => {
                    if (!response.data.error) {
                        if (!response.data.name)
                            response.data.name = response.data.documentId

                        // TODO: Cycle through Schema fields and set them to default
                        const schemaLength = response.data.schemaId?.json.length || 0;
                        let i:number, j:number;

                        for (i = 0; i < schemaLength; i++) {
                            const item = response.data.schemaId.json[i];
                            if (item.type !== 'object' && item.type !== 'reference')
                                response.data.json[item.name] = response.data?.json?.[item.name] || item?.default || "";
                            else
                                response.data.json[item.name] = response.data?.json?.[item.name] || "";
                                
                            if (item.type === "object") {
                                if (typeof response.data.json[item.name] !== 'object')
                                    response.data.json[item.name] = {};
                                const subLength = item.fields.length;
                                for (j = 0; j < subLength; j++) {
                                    const sub = item.fields[j];
                                    if (sub.type !== 'reference')
                                        response.data.json[item.name][sub.name] = response.data.json?.[item.name]?.[sub.name] || sub?.default || "";
                                    else 
                                        response.data.json[item.name][sub.name] = response.data.json?.[item.name]?.[sub.name] || "";
                                }
                            }
                        }
                        setUseDocument(response.data);
                        setUnsaved(false)
                        TitleService.set(response.data.name);

                        // Backup
                        axios.get(`${Config.apiUrl}/backups/list/${id}/${document}?lang=${localLanguage[1]}`,{ cancelToken: axiosCancelSource.current?.token, withCredentials: true }).then((response) => {
                            if (!response.data.error)
                                setBackupData(response.data);
                            else
                                toast.error(response.data.error);
                        }).catch(() => toast.error('Unexpected error occurred on backups!'));
                    } else
                        toast.error(response.data.error);
                }).catch((e: string) => {
                    toast.error('Unexpected error occurred on document data!');
                    console.log(e);
                });
        }

        axios.get(`${Config.apiUrl}/commits/list?type=document&filter=${id}&value=${document}&lang=${localLanguage[1]}`, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error)
                setCommitData(response.data);
            else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred on commit data!'));
// eslint-disable-next-line
    }, [id, document, localLanguage]);


    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();

        TitleService.set('Documents');
        getData();
        return () => axiosCancelSource.current?.cancel();
    }, [ getData ]);

    const updateSchema = (schemaId: string) => {
        if (useDocument) {
            const useData = useDocument;
            useData.schemaId = schemaData[parseInt(schemaId, 10)];

            setUseDocument({ ...useData });
            setUnsaved(true);
        }
    }

    const updateField = (name: string, value: string, useInteger:boolean = false, useArray:boolean = false) => {
        if (useDocument) {
            const useData = useDocument;

            if (!useData.json)
                useData.json = {};

            if (!useData.json[name])
                useData.json[name] = "";

            useData.json[name] =
                useInteger
                    ? parseInt(value, 10)
                    : useArray
                        ? JSON.parse(value)
                        : value;

            setUseDocument({ ...useData });
            setUnsaved(true);
        }
    }

    const updateSubField = (field: string, name: string, value: string, useInteger:boolean = false, useArray:boolean = false) => {
        if (useDocument) {
            const useData = useDocument;

            if (!useData.json)
                useData.json = {};

            if (!useData.json[field])
                useData.json[field] = {};

            useData.json[field][name] =
                useInteger
                    ? parseInt(value, 10)
                    : useArray
                        ? JSON.parse(value)
                        : value;

            setUseDocument({ ...useData });
            setUnsaved(true);
        }
    }

    const createTag = (value: string) => {
        if (useDocument) {
            if (!useDocument.tags)
                useDocument.tags = [];

            useDocument.tags.push(value);

            setUseDocument({ ...useDocument });
            setUnsaved(true);
        }
    }

    const removeTag = (key: number) => {
        if (useDocument && useDocument.tags) {
            if (useDocument.tags[key])
                delete useDocument.tags[key];

            useDocument.tags = Object.values(useDocument.tags);
            setUseDocument({ ...useDocument });
            setUnsaved(true);
        }
    }

    const updateDocument = (fieldName: string, value: string) => {
        if (useDocument) {
            if (fieldName === "draft" || fieldName === "useSEOTab")
                useDocument[fieldName] = (value !== "0");
            else if (fieldName === "documentId" || fieldName === "name" || fieldName === "created")
                useDocument[fieldName] = String(value);

            setUnsaved(true);
            setUseDocument({...useDocument});
        }
    }

    const backupDocument = () => {
        if (useDocument && id && document) {
            const useBackupData = {
                _id: String(new Date().getTime()),
                collectionId: id,
                documentId: document,
                json: useDocument,
                created: new Date().toISOString()
            }
            setBackupData([useBackupData, ...backupData]);
        }
    }

    const saveDocument = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(useDocument);
        const formData = { id: document, collection: id, document: JSON.stringify(useDocument), _csrf };

        axios.post(`${Config.apiUrl}/documents/update`, formData, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                setUnsaved(false);
                backupDocument();

                if (useDocument && (!useDocument.name || useDocument.name === "")) {
                    useDocument.name = useDocument.documentId;
                    setUseDocument({ ...useDocument });
                }

                if (document !== useDocument?.documentId)
                    setRedirect(`/collection/${id}/${useDocument?.documentId}`);

                toast.success("Your changes have been saved!");
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const deleteDocument = () => {
        const formData = { name: document, collection: id, _csrf };
        axios.post(`${Config.apiUrl}/documents/remove`, formData,{
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error)
                setRedirect(`/collection/${id}`);
            else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const restoreDocument = (backup: DocumentData) => {
        if (useDocument) {
            backup._id = useDocument._id;
            setUseDocument({ ...backup });
            setToggleBackups(false);
            setUnsaved(true);
            toast.success("Restored!");
        }
    }

    return (
        useDocument ? (
            <form method="post" onSubmit={(e) => saveDocument(e)}>
                <ReactTooltip />
                <Prompt when={unsaved} message={"You have unsaved changes, are you sure you want to leave without saving?"} />
                {redirect && <Redirect to={redirect} />}
                <div style={{ zIndex: 51 }} className={`fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-30 transition ease-in-out duration-300 ${modal ? 'opacity-100' : 'pointer-events-none opacity-0'}`}>
                    <div className={"flex h-screen"}>
                        <div className={"relative w-11/12 md:w-4/5 lg:w-4/6 xl:w-128 m-auto shadow-lg rounded-2xl bg-white p-5"}>
                            <button type={"button"} className={"absolute top-5 right-5 button-tiny button-red"} onClick={() => setModal(false)}>
                                <FontAwesomeIcon icon={faTimes} className={"text-2xl mt-1"} /> 
                            </button>

                            <h2 className={"pageHeading border-b border-gray-200 pb-4 mb-2"}>Settings</h2>
                            <div className={"py-4 grid grid-cols-1 gap-6"}>
                                <div>
                                    <label className="font-semibold block mb-1">Name</label>
                                    <input type={"text"} className="input-basic" onChange={(e) => updateDocument("name", e.target.value)} value={useDocument.name} />
                                </div> 
                                <div>
                                    <label className="font-semibold block mb-1">Slug</label>
                                    <input type={"text"} className={`input-basic ${((sessionData?.level || 0) <= 0) && 'pointer-events-none opacity-50'}`} onChange={(e) => updateDocument("documentId", e.target.value)} value={useDocument.documentId} />
                                    <div className="text-sm opacity-70">Requires Level 1 Editor Permission - Change at your own risk.</div>
                                </div> 
                                <div>
                                    <label className="font-semibold block mb-1">Schema</label>
                                    {sessionData && sessionData.level >= 2 && (
                                        <div className={"relative"}>
                                            <button type={"button"} key={"schemaDropdown"} onClick={() => setDropdown(!dropdown)} className={"input-basic text-left"}>
                                                <FontAwesomeIcon icon={faCropAlt} className={"mr-2"} />
                                                {useDocument.schemaId.schemaId}
                                                <FontAwesomeIcon icon={faChevronDown} className={"ml-2 text-xs"} />
                                            </button>
                                            <div className={`dropdown ${dropdown ? 'is-active' : 'not-active'}`}>
                                                {schemaData.sort((a, b) => a.schemaId.localeCompare(b.schemaId)).map((item, key) => item._id && (
                                                    <button type={"button"} onClick={() => { updateSchema(String(key)); setDropdown(false) }} key={item._id} className={`${useDocument.schemaId.schemaId === item.schemaId ? 'is-active' : ''} item`}>
                                                        {item.schemaId}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <label className="font-semibold block mb-1">Created</label>
                                    <DateTimePicker className="input-basic" onChange={(e: Date) => updateDocument("created", String(e.toISOString()))} value={new Date(useDocument.created)} />
                                    <div className="text-xs text-gray-500 mt-px">Date must match ISO 8061 guidelines.</div>
                                </div>
                                <DeleteDocumentButton
                                    name={useDocument.name || useDocument.documentId}
                                    onClick={() => confirmAlert({
                                        message: "Are you sure you want to do delete this document?",
                                        value: String(useDocument?.documentId),
                                        onSuccess: () => deleteDocument()
                                    })}
                                    language={useDocument?.language}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-container-none">
                    <div className="header">
                        <div className="w-auto mr-auto">
                            <h1 className="block pageHeading pr-4">{useDocument.name || useDocument.documentId || ''}</h1>
                            <Breadcrumb className="mb-4" items={[ { name: 'Content', uri: '/content' }, { name: id || '', uri: `/collection/${id}/` }, { name: (useDocument.name || useDocument.documentId || ''), uri: window.location.pathname } ]} />
                        </div>

                        <div className={"w-full md:w-auto my-auto"}>
                            <div className={`flex flex-wrap gap-2`}>
                                <RestfulUrlButton doc={useDocument} sessionData={sessionData} collectionId={id} />

                                <button data-tip={useDocument.useSEOTab ? "Disable SEO Section" : "Enable SEO Section"} type={"button"} className={"w-14 button-small button-black"} onClick={() => updateDocument("useSEOTab", !useDocument.useSEOTab ? "1" : "0")}>
                                    <FontAwesomeIcon icon={faSearchLocation} className={`text-lg  ${(useDocument.useSEOTab === '1' || useDocument.useSEOTab === true) ? 'text-cyan-100' : ''}`} />
                                </button>

                                <button data-tip="Visibility Status" type={"button"} className={"w-14 button-small button-black"} onClick={() => updateDocument("draft", !useDocument.draft ? "1" : "0")}>
                                    <FontAwesomeIcon icon={useDocument.draft ? faEyeSlash : faEye} className={`text-lg  ${useDocument.draft ? 'text-cyan-100' : ''}`} />
                                </button>

                                <div className={"relative"}>
                                    <button type="button" data-tip="Backups" onClick={() => setToggleBackups(!toggleBackups)} className={`button-small button-black`}>
                                        <FontAwesomeIcon icon={faUndo} />
                                    </button>
                                    <div className={`dropdown is-large is-right ${toggleBackups ? 'is-active' : 'not-active'}`}>
                                        {backupData.length !== 0 ?
                                            backupData.map((item) => (
                                                <button key={item._id} type={"button"} onClick={() => restoreDocument(item.json)} className={"item"}>
                                                    <div className={"text-sm uppercase font-semibold text-gray-600"}>
                                                        <FontAwesomeIcon icon={faUndo} className={"mr-1"} /> {moment(item.created).format('h:mma')}
                                                    </div>
                                                    <div className={"text-xs text-gray-400"}>
                                                        Created {moment(item.created).format('MMMM D')}
                                                    </div>
                                                </button>
                                            ))
                                        : <div className={"py-10 text-gray-500 text-center"}>No backups yet!</div>}
                                    </div>
                                </div>
                                <button type="button" data-tip="Settings" onClick={() => setModal(!modal)} className={`button-small button-black`}>
                                    <FontAwesomeIcon icon={faCog} />
                                </button>
                                <button type="submit" className={`button-small button-cyan ${unsaved ? 'opacity-100' : 'opacity-50 pointer-events-none'}`}>Save Changes</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-container-none">
                    <div className="grid grid-cols-1 xl:col-span-3 2xl:grid-cols-4">
                        <div className="col-span-full xl:col-span-2 2xl:col-span-3 p-6"> 
                            <div className="grid grid-cols-1 gap-8 inputDocument">
                                <GlobalObject useDocument={useDocument} updateField={updateField} updateSubField={updateSubField} fields={(useDocument.schemaId.json || []) as ObjectProps[]} />
                            </div> 
                        </div>
                        <div className="col-span-full xl:col-span-1">
                            <div className="grid grid-cols-1 inputDocument">
                                {useDocument.useSEOTab ? (
                                    <div className="p-6 border-b border-t xl:border-t-0">
                                        <div className={"text-lg font-bold text-gray-900 uppercase pb-2"}>SEO / Metadata</div>
                                        <div className="inputDocument grid grid-cols-1 gap-8">
                                            <GlobalObject isMetadata useDocument={useDocument} updateField={updateField} updateSubField={updateSubField} fields={([{"name":"metadata","title":"SEO / Metadata","hint":"This is an example text","type":"object","required":"0","_internalId":0,"fields":[{"name":"title","title":"Title","required":false,"type":"string","maxlength":"100","default":""},{"name":"description","title":"Description","required":false,"maxlength":"200","type":"text","default":""},{"name":"keywords","title":"Keywords","required":false,"maxlength":"75","type":"string","default":""},{"name":"image","title":"Featured Image","required":false,"type":"image","default":""}]}]) as any} />
                                        </div>
                                    </div>
                                ) : ''}

                                {(tagData && tagData.length !== 0) && (
                                    <div className="p-6 border-b">
                                        <div className={"text-lg font-bold text-grayblue-100 uppercase pb-2"}>Tags</div>
                                        <select className={"input-basic"} onChange={(e) => createTag(e.target.value)}>
                                            <option key={""} value={""}>Uncategorized</option>
                                            {tagData.map((item, key) => (!useDocument.tags || !useDocument.tags.includes(item.name)) && <option key={key} value={item.name}>{item.name}</option>)}
                                        </select>

                                        {useDocument.tags && useDocument.tags.length !== 0 && (
                                            <>
                                                <div className={"flex flex-wrap gap-2 py-6"}>
                                                    {useDocument.tags.map((item, key) => (
                                                        <div key={key} className={"relative pl-3 pr-6 py-1.5 bg-gray-300 text-gray-800 rounded-full text-left text-xs"}>
                                                            {item}
                                                            <button type={"button"} onClick={() => removeTag(key)} className={"absolute top-0 right-0 bottom-0 mr-2.5 focus:outline-none text-xs text-gray-600 hover:text-gray-500"}>
                                                                <FontAwesomeIcon icon={faTimes} />
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                                

                                <div className="p-6">
                                    <div className={"text-lg font-bold text-gray-900 uppercase pb-2"}>Revisions (last 20)</div>
                                    {commitData.map((item, index) => index <= 19 && <Commit key={index} size={'small'} {...item} /> )}
                                    {commitData.length === 0 ? <div className="py-24 px-10 bg-white text-center font-semibold text-gray-500 rounded-lg">No changes have been made yet.</div> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        ) : <Loading />
    )
}

export default Documents;
