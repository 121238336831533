import {FC, useState, useEffect, useRef, useCallback, FormEvent, useMemo} from 'react';
import {Link, useParams} from 'react-router-dom';
import { DocumentData } from '../../../interfaces/DocumentData';
import {faCaretDown, faCaretUp, faEye, faPlus, faSearch, faSort, faTag, faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, {CancelTokenSource} from "axios";
import {toast} from "react-toastify";
import Config from "../../../Config";
import moment from "moment";
import useCSRF from "../../hooks/useCSRF";
import TitleService from "../../../services/TitleService";
import {useTagContext} from "../../contexts/Tag.context";
import Breadcrumb from '../../other/Breadcrumb';
import InputGroup from '../../other/InputGroup';
import Commit from '../../other/Commit';
import Loading from '../../Loading';
import { CollectionData } from '../../../interfaces/CollectionData';
import {useBaseContext} from '../../contexts/Base.context';
import Pagination from '../../other/Pagination';
import debounce from 'lodash/debounce';

interface ParamsProps {
    type: string;
    id?: string;
}


const itemsPerPage = 50
const Collections: FC = () => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);
    const { _csrf } = useCSRF();
    const { tagData } = useTagContext();
    const [ loaded, setLoaded ] = useState<boolean>(false);
    const [ modal, setModal ] = useState<boolean>(false);
    const [ input, setInput ] = useState<string>('');
    const [ search, setSearch ] = useState<string>('');
    const [ filter, setFilter ] = useState<boolean | null>(null);
    const [ tag, setTag ] = useState<string>('');
    const [ dropdown, setDropdown ] = useState<number>(0);
    const [ documentData, setDocumentData ] = useState<DocumentData[]>([]);
    const { type = 'collections', id } = useParams<ParamsProps>();
    const [ skip, setSkip ] = useState<number>(0);
    const { localLanguage } = useBaseContext()

    // Order Types
    const [ orderType, setOrderType ] = useState<string>('DESC');
    const [ orderField, setOrderField ] = useState<string>('created');

    // Update Order
    const updateOrder = (type: string, field: string) => {
        if (!['DESC', 'ASC'].includes(type))
            type = 'DESC';

        if (!['documentId', 'updated', 'created'].includes(field))
            field = 'created';

        setOrderType(type);
        setOrderField(field);
    }

   // Memoize the URL for the getData function
   const url = useMemo(() => {
    let baseURL = `${Config.apiUrl}/documents/list/${id}?lang=${localLanguage[1]}`;
    if (type === 'tagged') baseURL = `${Config.apiUrl}/documents/list/tagged/${id}`;
    return baseURL;
}, [id, type, localLanguage]);

const getData = useCallback(() => {
    axios.get(url, {
        cancelToken: axiosCancelSource.current?.token,
        withCredentials: true
    }).then((response) => {
        if (!response.data.error) {
            TitleService.set(`${id}`);
            const sortedData = response.data?.sort((a: CollectionData, b: CollectionData) => {
                // @ts-ignore
                if (orderType === 'ASC') return a[orderField] < b[orderField] ? -1 : 1;
                // @ts-ignore
                return a[orderField] > b[orderField] ? -1 : 1;
            });
            setDocumentData(sortedData);
            setLoaded(true);
        } else {
            toast.error(response.data.error);
        }
    }).catch(() => toast.error('Unexpected error occurred!'));
}, [url, orderType, orderField, id]);

useEffect(() => {
    axiosCancelSource.current = axios.CancelToken.source();
    TitleService.set('Collections');
    const debouncedGetData = debounce(getData, 300);
    debouncedGetData();
    return () => {
        axiosCancelSource.current?.cancel();
        debouncedGetData.cancel();
    };
}, [getData]);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formData = { name: input, collection: id, _csrf };
        axios.post(`${Config.apiUrl}/documents/create`, formData, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                getData();
                setModal(false);
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const lowerCaseSearch = search.toLowerCase()
    const filteredDocumentData = documentData.filter((doc) => {
        if (!search) {
            return true
        }
        return doc.name.toLowerCase().includes(lowerCaseSearch)
    })

    return (
        <>
            <div style={{ zIndex: 51 }} className={`fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-30 z-20 ${modal ? 'opacity-100' : 'pointer-events-none opacity-0'} transition ease-in-out duration-300`}>
                <div className={"flex h-screen"}>
                    <div className={"relative w-11/12 md:w-4/5 lg:w-4/6 xl:w-128 m-auto shadow-lg rounded-2xl bg-white p-5"}>
                        <button type={"button"} className={"absolute top-5 right-5 button-tiny button-red"} onClick={() => setModal(false)}>
                            <FontAwesomeIcon icon={faTimes} className={"text-xl mt-1"} />
                        </button>

                        <h2 className={"pageHeading border-b border-gray-200 pb-4 mb-2"}>Create a Document</h2>
                        <form method={"post"} onSubmit={handleSubmit} className={"grid grid-cols-1 py-4 gap-8"}>
                            <div>
                                <label className={"block pb-2"}>Name</label>
                                <input type={"text"} className={"input-basic"} name={"name"} placeholder={"Maximum: 96 characters"} onChange={(e) => setInput(e.target.value)} required />
                            </div>
                            <div className={"flex"}>
                                <button type={"submit"} className={"button-small button-cyan ml-auto"}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="header-container-none">
                <div className="header">
                    <div className="w-auto mr-auto">
                        <h1 className={"pageHeading"}>{id || ''}</h1>
                        <Breadcrumb className="mb-4" items={[ { name: 'Content', uri: '/content' }, { name: (id || ''), uri: window.location.pathname } ]} />
                    </div>
                    <div className={"w-auto my-auto"}>
                        <Pagination
                            skip={skip}
                            total={filteredDocumentData.length}
                            perPage={itemsPerPage}
                            next={() => setSkip(skip + itemsPerPage)}
                            prev={() => setSkip(skip - itemsPerPage)}
                        />
                    </div>
                    <div className={"w-full md:w-auto my-auto"}>
                        <InputGroup type="text" className="w-64 border" icon={faSearch} onChange={(e) => setSearch(e)} value={search} placeholder="Search" />
                    </div>
                    <div className={"w-full md:w-auto my-auto"}>
                        <div className={"relative"}>
                            <button type={"button"} onClick={() => setDropdown(dropdown === 1 ? 0 : 1)} className={"button-small button-black"}>
                                <FontAwesomeIcon icon={faEye} className={"mr-1"} /> {filter === null ? 'All' : filter ? 'Draft' : 'Public'} <FontAwesomeIcon icon={faCaretDown} className={"ml-1"} />
                            </button>

                            <div className={`dropdown ${dropdown === 1 ? 'is-active' : 'not-active'}`}>
                                <button type={"button"} onClick={() => { setDropdown(0); setFilter(null) } } className={`${filter === null ? 'is-active' : ''} item`}>
                                    All
                                </button>
                                <button type={"button"} onClick={() => { setDropdown(0); setFilter(true) } } className={`${filter === true ? 'is-active' : ''} item`}>
                                    Draft
                                </button>
                                <button type={"button"} onClick={() => { setDropdown(0); setFilter(false) } } className={`${(filter !== null && !filter) ? 'is-active' : ''} item`}>
                                    Public
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className={"w-full md:w-auto my-auto"}>
                        <div className={"relative"}>
                            <button type={"button"} onClick={() => setDropdown(dropdown === 2 ? 0 : 2)} className={"button-small button-black"}>
                                <FontAwesomeIcon icon={faTag} className={"mr-1"} /> {tag ? tag : 'Tags'} <FontAwesomeIcon icon={faCaretDown} className={"ml-1"} />
                            </button>

                            <div className={`dropdown ${dropdown === 2 ? 'is-active' : 'not-active'}`}>
                                <button type={"button"} onClick={() => { setDropdown(0); setTag('') } } className={`${tag === '' ? 'bg-gray-200 hover:bg-gray-100' : 'hover:bg-gray-100'} rounded-t transition ease-in-out duration-300 focus:outline-none block w-full px-4 py-2 text-left text-gray-900 font-semibold`}>
                                    All Tags
                                </button>
                                {tagData.map((item) => (
                                    <button type={"button"} onClick={() => { setDropdown(0); setTag(item.name) } } className={`${tag === item.name ? 'is-active' : ''} item`}>
                                        {item.name}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="w-full sm:w-auto my-auto">
                        <button type={"button"} onClick={() => setModal(true)} className={"button-small button-cyan"}>
                            <FontAwesomeIcon icon={faPlus} className={"mr-1"} /> Create {id?.slice(-1) === 's' ? id.slice(0, -1) : id || 'Collection'}
                        </button>
                    </div>
                </div>
            </div>

            {loaded ? (
                <div className="main-container-none"> 
                    <div className="table-container">
                        <div className="table-head">
                            <div className="w-8">#</div>
                            <button type="button" onClick={() => updateOrder(orderType === 'ASC' ? 'DESC' : 'ASC', 'documentId')} className="mr-auto focus:outline-none text-left w-64 md:w-72 lg:w-96 xl:w-128">
                                Name
                                <FontAwesomeIcon className={`ml-2 ${orderField !== 'documentId' ? 'pointer-events-none opacity-30' : ''}`} icon={orderField === 'documentId' ? (orderType === 'ASC' ? faCaretUp : faCaretDown) : faSort} />
                            </button>
                            <button onClick={() => updateOrder(orderType === 'ASC' ? 'DESC' : 'ASC', 'updated')} className="w-44 hidden xl:block pointer-events-none">
                                Last Updated
                                <FontAwesomeIcon className={`opacity-0 ml-2 ${orderField !== 'updated' ? 'pointer-events-none opacity-0' : ''}`} icon={orderField === 'updated' ? (orderType === 'ASC' ? faCaretUp : faCaretDown) : faSort} />
                            </button>
                            <div className="w-24">Status</div>
                            <button type="button" onClick={() => updateOrder(orderType === 'ASC' ? 'DESC' : 'ASC', 'created')} className="focus:outline-none hidden sm:block text-left w-48">
                                Created
                                <FontAwesomeIcon className={`ml-2 ${orderField !== 'created' ? 'pointer-events-none opacity-30' : ''}`} icon={orderField === 'created' ? (orderType === 'ASC' ? faCaretUp : faCaretDown) : faSort} />
                            </button>
                        </div>
                        {loaded && (
                            filteredDocumentData.length !== 0
                                ? filteredDocumentData.slice(skip, skip + itemsPerPage).map((item, index: number) => (
                                    <Link key={item.documentId} to={`/collection/${item.collectionId}/${item.documentId}`} className={`table-row`}>
                                        <div className="w-8 font-semibold my-auto">{index + 1}.</div>
                                        <div className="w-64 md:w-72 lg:w-96 xl:w-128 mr-auto my-auto">{item.name || item.documentId}</div>
                                        <div className="w-44 hidden xl:block my-auto">
                                            {item.commitUpdated ? <Commit size="table" {...item.commitUpdated} /> : ''}
                                        </div>
                                        <div className="w-24 my-auto">{item.draft ? <span className={"font-semibold text-yellow-400"}>Draft</span> : <span className={"font-semibold text-green-400"}>Public</span>}</div>
                                        <div className="hidden sm:block w-48 my-auto">{moment(item.created).format('LL')}</div>
                                    </Link>
                                ))
                                : <div className={"table-none"}>There are no documents here, <button type={"button"} onClick={() => setModal(true)} className={"focus:outline-none text-gray-400 hover:text-gray-600 transition font-semibold"}>create one</button>!</div>
                        )}
                    </div>
                </div>
            ) : <Loading isPartial isDark />}
        </>
    )
}

export default Collections;
