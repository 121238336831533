import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, FormEvent, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { ExternalData } from '../../interfaces/ExternalData';

interface Props {
    externalData: ExternalData[];
    handleSubmit(e: FormEvent<HTMLFormElement>, input: string, url: string): void
    handleDelete(externalId: string): void
}

export const LinkManager: FC<Props> = ({ externalData, handleSubmit, handleDelete }) => {
    const [url, setUrl] = useState('');
    const [input, setInput] = useState('');
    const onSubmitClick = (e: FormEvent<HTMLFormElement>) => {
        handleSubmit(e, input, url);
        setUrl('');
        setInput('');
    }
    return (
        <>
            <h2 className={"pageHeading border-b border-gray-200 pb-4 mb-2"}>External Manager</h2>
            <div className={"py-4"}>
                <div className={"smallHeading pb-2"}>Manage</div>
                <div className={"h-48 overflow-y-auto overflow-x-hidden"}>
                    {externalData.length !== 0 ? externalData.map((item) => (
                        <div className={"flex px-4 py-2 mb-2 bg-gray-200 rounded-lg text-gray-700"}>
                            <div className={"w-full font-semibold"}>
                                {item.externalId}
                            </div>
                            <div className={"w-auto ml-auto"}>
                                <button type={"button"} className="text-red-400 hover:text-red-500 focus:outline-none transition ease-in-out duration-300" onClick={() => confirmAlert({ message: "Are you sure you want to do delete this external link?", buttons: [{ label: "Yes", onClick: () => handleDelete(item.externalId) }, { label: "No", onClick: () => false }] })}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </div>
                        </div>
                    )) : <div className={"w-full block pt-20 text-center text-gray-900 font-semibold"}>You have no external links created.</div>}
                </div>

                <h2 className={"smallHeading pt-4"}>Create</h2>
                <form method={"post"} onSubmit={onSubmitClick} className={"grid grid-cols-1 gap-2"}>
                    <div className={"pt-2"}>
                        <input type={"text"} className={`input-basic`} name={"name"} placeholder={"Name - Only letters, numbers and underscores allowed."} onChange={(e) => setInput(e.target.value)} value={input} required />
                    </div>
                    <input type={"text"} className={"input-basic"} name={"url"} placeholder={"URL - https://example.com/"} onChange={(e) => setUrl(e.target.value)} value={url} required />
                    <div className={"flex"}>
                        <button type={"submit"} className={"button-small button-cyan ml-auto"}>
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}
