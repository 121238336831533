import { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import { ObjectProps } from './GlobalObject';
import PrintWidth from './PrintWidth';
import PrintWidthPopup from './PrintWidthPopup';
import CharacterLimitWarning from './MaxCharacterLimitText';

interface IProps extends ObjectProps {
    isSEO?: boolean;
}

const Input:FC<IProps> = (props) => {

    const maxLength = Number(props.maxlength) || 768
    const minLength = Number(props.minlength) || 0

    return (
        <>
            <ReactTooltip />
            <div>
                <div className='flex align-middle'><label htmlFor={props.name}>{props.title || props.name} {props.required && <span className="text-gray-400 text-xs">(required)</span>}</label> {props.isSEO && <div> <PrintWidthPopup fieldName={props.name} /> </div> }</div>
                {props.hint && <div className={"text-sm text-gray-500 pb-2"}>{props.hint}</div>}
                <input id={props.name} type="text" name={props.name} required={props.required} value={props.value} placeholder={props.hint || 'Text field'} maxLength={maxLength} minLength={minLength} onChange={props.onChange} />
                {props.isSEO && <div> <PrintWidth value={props.value || ''} fieldName={props.name} /> </div> }
                <div className="flex justify-between pt-1">
                  <CharacterLimitWarning
                    maxLength={maxLength}
                    minLength={minLength}
                    valueLength={props.value?.length || 0}
                  />
                </div>
            </div>
        </>
    )
}

export default Input;
