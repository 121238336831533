import {ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState} from 'react';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import {UserData} from "../../interfaces/UserData";
import {faCog, faPlus, faSearch, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, {CancelTokenSource} from "axios";
import { useEditorContext } from '../contexts/Editor.context';
import Config from "../../Config";
import useCSRF from "../hooks/useCSRF";
import TitleService from "../../services/TitleService";
import Avatar from "../other/Avatar";
import Breadcrumb from '../other/Breadcrumb';
import { useBaseContext } from '../contexts/Base.context';
import moment from 'moment';
import Pagination from '../other/Pagination';
import InputGroup from '../other/InputGroup';
import {EditUserForm} from './Users/EditUserForm';
import {CreateUserForm} from './Users/CreateUserForm';


const Users = () => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);

    const { sessionData } = useEditorContext();
    const { projectData } = useBaseContext();
    
    const { _csrf } = useCSRF();
    const [ userData, setUserData ] = useState<UserData[]>([]);
    const [ selectedUser, setSelectedUser ] = useState<UserData>()
    const [ modal, setModal ] = useState<number | null>(null);
    const [ emailAddress, setEmailAddress ] = useState('');

    const [ skip, setSkip ] = useState<number>(0);
    const [ search, setSearch ] = useState<string>('');
    const [ activeSearch, setActiveSearch ] = useState<string>('');

    const getUsers = useCallback(async () => {
        axios.get(`${Config.apiUrl}/users/list`, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                setUserData(response.data);
                
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }, []);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        TitleService.set('Users');
        getUsers().then(() => console.log('Data loaded'));
        return () => axiosCancelSource.current?.cancel();
    }, [ getUsers ]);

    const handleFormSuccess = () => {
      setModal(null);
      setSelectedUser(undefined)
      getUsers()
    }

    const handleUserRemove = (item: UserData) => {
        const email = item.emailAddress
        const formData = { name: email, _csrf }
        axios.post(`${Config.apiUrl}/users/remove`, formData,{
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                toast.success(`Removed ${email}`);
                getUsers();
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'));
    }

    const handleSearch = (e: FormEvent<HTMLFormElement>) => { e.preventDefault(); setActiveSearch(search); }

    const onUsersFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.files)
        const files = e.target.files
        const file = files?.item(0)
        console.log(file)
        e.target.value = '';
        const formData = new FormData();
        if (!file) {
            toast.error('Unexpected error occurred!')
            return
        }
        formData.append('file', file, file.name)
        formData.append('_csrf', _csrf)
        axios.post(`${Config.apiUrl}/users/create_bulk`, formData, {
            cancelToken: axiosCancelSource.current?.token,
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            if (!response.data.error) {
                toast.success('Users are added')
                getUsers();
            } else
                toast.error(response.data.error);
        }).catch(() => toast.error('Unexpected error occurred!'))
    }

    const newUserData: UserData[] = [];
    userData.forEach((item) => 
        (!activeSearch || (
            (item.emailAddress.toLowerCase().includes(activeSearch.toLowerCase()))
        )) ? newUserData.push(item) : null);

    return (
        <>
            <div className={`fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-30 z-50 ${modal !== null ? 'opacity-100 translate-y-0' : 'pointer-events-none opacity-0 translate-y-1'} transition ease-in-out duration-500`}>
                <div className={"flex h-screen"}>
                    <div className={"relative w-11/12 md:w-4/5 lg:w-4/6 xl:w-128 m-auto shadow-lg rounded-2xl bg-white p-5"}>
                        <button type={"button"} className={"absolute top-5 right-5 button-tiny button-red"} onClick={() => setModal(null)}>
                            <FontAwesomeIcon icon={faTimes} className={"text-xl mt-1"} />
                        </button>

                        <h2 className={"pageHeading border-b border-gray-200 pb-4 mb-2 pr-10"}>{!modal ? 'Add an User' : `Edit ${emailAddress}`}</h2>
                        {modal === 1 && selectedUser && projectData && (
                          <EditUserForm
                            user={selectedUser}
                            projectData={projectData}
                            onClose={handleFormSuccess}
                            />)}
                        {modal === 0 && projectData && (<CreateUserForm projectData={projectData} onClose={handleFormSuccess} />)}
                    </div>
                </div>
            </div>

            <div className="header-container-none">
                <div className="header">
                    <div className="w-auto mr-auto">
                        <h1 className={"pageHeading"}>Users</h1>
                        <Breadcrumb className="mb-4" items={[ { name: 'Users', uri: window.location.pathname } ]} />
                    </div>
                    <div className={"w-auto my-auto"}>
                        <Pagination 
                            skip={skip} 
                            total={userData.length} 
                            perPage={20} 
                            next={() => setSkip(skip + 20)} 
                            prev={() => setSkip(skip - 20)} 
                        />
                    </div>
                    <div className={"w-full md:w-auto my-auto"}>
                        <form onSubmit={handleSearch}>
                            <InputGroup type="text" icon={faSearch} placeholder="Search" value={search} onChange={(e) => setSearch(e)} />
                        </form>
                    </div>
                    {(sessionData && sessionData.level >= 2) && (
                        <div className="my-auto media-upload-button">
                        <input accept=".csv" id={"userUpload"} name={"userUpload"} style={{ display: 'none' }} type={"file"} onChange={onUsersFileSelected} />
                        <label style={{ color: '#fff', padding: '10px 16px', textTransform: 'none' }} className="button-small button-black hover:cursor-pointer" htmlFor={"userUpload"}>
                        <FontAwesomeIcon className="mr-1" icon={faPlus} /> Add Users Bulk
                        </label>
                        </div>
                    )}
                    {(sessionData && sessionData.level >= 2) && (
                        <div className="w-auto my-auto">
                            <button type={"button"} onClick={() => setModal(0)} className={"button-small button-cyan"}>
                                <FontAwesomeIcon className="mr-1" icon={faPlus} /> Add User
                            </button>
                        </div>
                    )} 
                </div>
            </div>
 
            <div className={"main-container-none"}>
                <div className="table-container">
                    <div className="table-head">
                        <div className="w-8" />
                        <div className="mr-auto">Email Address</div>
                        <div className="w-32">Role</div>
                        <div className="w-32">Verified</div>
                        <div className="w-40">Created</div>
                        <div className="w-20" />
                    </div>
                    {newUserData.reverse().map((item, index) => (
                        <div key={item.emailAddress} className={"table-row"}>
                            <div className="w-8 my-auto font-semibold">{index + 1}</div>
                            <div className="mr-auto">
                                <div className="flex">
                                    <div className="w-auto">
                                        <Avatar size={'extratiny'} editorId={item.emailAddress} />
                                    </div>
                                    <div className="w-auto my-auto pl-2">
                                        {item.emailAddress}
                                    </div>
                                </div>
                            </div>
                            <div className="w-32 my-auto">{item.role || 'None'}</div>
                            <div className="w-32 my-auto">{item.activationCode ? 'No' : 'Yes'}</div>
                            <div className="w-40 my-auto">{moment(item.created).fromNow()}</div>
                            <div className="w-20 my-auto">
                                {(sessionData && sessionData?.level >= 2) ? (
                                    <>
                                        <button type={"button"} className={"button-tiny button-cyan mr-1"} onClick={() => { setEmailAddress(item.emailAddress); setSelectedUser(item); setModal(1);}}>
                                            <FontAwesomeIcon icon={faCog} />
                                        </button>
                                        <button type={"button"} className={"button-tiny button-red"} onClick={() => confirmAlert({ message: "Are you sure you want to do this?", buttons: [{ label: "Yes", onClick: () => handleUserRemove(item) }] })}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </>
                                ) : <></>}
                            </div>
                        </div>
                    ))}
                    {(newUserData && newUserData.length === 0) && <div className={"table-none"}>You do not have any users yet, <button type={"button"} onClick={() => setModal(0)} className={"focus:outline-none text-gray-400 hover:text-gray-600 transition font-semibold"}>add one</button>!</div>}
                </div>
            </div>
        </>
    );
}

export default Users;
